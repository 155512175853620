const casesArray = [
    {
        img: 'img/cases/Bindtechs.svg',
        mark: 'Dashboards',
        title: 'BINDTECHS - Big Data no-code Dashboard Builder',
        description: `Dashboard builder is a software that helps to create visual and interactive dashboards. 
                      These dashboards are used to display data in a graphical and organized format. The data can be pulled from 
                      multiple sources and displayed in various charts, tables, and graphs. The software also allows users to customize 
                      the dashboard with different colors, fonts, and themes. 
                      Dashboard builder is a great tool for data analysis, reporting, and decision-making. It helps businesses to 
                      gain insights into their data and make informed decisions.`,
        aboutClient: `BINDTECHS is a cutting-edge technology startup offering code-free cloud software for different businesses.
                      This is a Flexible Builder with a full set of tools for Supervisory Control And Data Acquisition`,
        generalChallanges: `The product should help users to control operations within the company, to structure large flows 
                            of information in one space. The data should be displayed in the form of convenient analytical 
                            dashboards and infographics. The main challenge is to create a solution that can be easily 
                            customized to meet the individual needs of different business sectors working with data volumes. 
                            The technology should be user-friendly and intuitive. It should provide options for integrating 
                            the product with customers' internal systems.`,
        solutions: `Our team created a comprehensive web application with extended functionality for small to large businesses, 
                    including: API integrations, Data performance dashboards, Data mapping & engineering, Business intelligence 
                    & dashboard reporting, Hierarchical live diagrams to create one-picture summaries, Dynamic plug & play modules 
                    for everything: SQL, NoSQL, API-based, and realtime (MQTT, Sockets, & event brokers).
                    The use of no-code cloud tools allows you to adapt the product to different industries and needs. Currently, 
                    the main areas in which BINDTECHS can be used are Investment Management, Sales and Marketing, Manufacturing, 
                    and Business Health Services. In the product's workspace, data flows can be visualized with various types 
                    of tables and charts
                    The no-code solution helps to avoid involving developers in case of changes in the project organization 
                    and adding new data streams. No matter the complexity of the project, a no-code system allows you to master 
                    and use BindTechs technology within a few days of implementation. It is compatible with most PLC and 
                    RTU models and supports various connection protocols. An important point is that the product can be applied 
                    to the White Label concept`,
        results: `As a result of our work on the product, we have a solution that helps to structure and visualize 
                  data flows for different types of businesses. 
                  Bindtechs was implemented in a system for analyzing key indicators at a large production facility 
                  for a customer from China. 
                  The product helped automate workflows and monitor KPIs.`
    },
    {
        img: 'img/cases/Fragma.svg',
        mark: 'Crypto',
        title: 'Custom Tokensale Portal (ICO / STO)',
        description: `Dashboard infographics provide real-time tracking of token sales and investments. 
                      Monitor the performance of fundraising campaign and make informed decisions. 
                      The infographics are designed to be user-friendly and easy to understand, providing with 
                      a visual representation of key metrics such as total investments, number of tokens sold, 
                      and revenue generated. The system also provides real-time data on the number of transactions, 
                      the number of participants, the average transaction size and more. Additionally, 
                      the infographics are interactive and customizable, allowing you to drill down into specific 
                      data points and view the information that matters most.`,
        aboutClient: `The Client is a StartUp that has decided to take its first steps in the cryptocurrency industry.
                    The goal of the project was to create a Customer Platform, 
                    with all the traditional functions typical for representatives of the 
                    the primary placement and trading of tokens. 
                    A characteristic feature of the project is the availability of private and public token sale options.`,
        generalChallanges: `The main tasks we faced were to create 
                    a new website with a unique style for brand awareness.
                    Ensuring user security, which is very important for any cryptocurrency project.
                    The possibility of high-quality user interaction with a personal wallet, 
                    participation in token sales, and tracking statistics on dashboards.`,
        solutions: `The SolidOne team prepared the technological stack necessary to complete the task.
                    Design department developed the necessary materials in strict accordance with the client's requirements and wishes.
                    The architecture and feature map of the project was developed. A detailed functional specification was prepared.
                    Intuitive navigation and a WEB3-compatible interface were implemented.
                    Interactive dashboards for tracking infographics in real time, and monitoring the effectiveness 
                    of token sales companies were created. Implemented KYC verification for secure trading and financial transactions`,
        results: `Thanks to the fruitful cooperation between FRAGMA and SoliOne, we have created a powerful platform for 
                    the initial public offering of new tokens.
                    Now users will be able to invest in various cryptocurrencies more simply.
                    Due to the high level of security, users can 
                    use this program without any restrictions`
    },
    {
        img: 'img/cases/FelixCo.svg',
        mark: 'NFT',
        title: 'Felix Co Accounting and Tax Report Web App system',
        description: `Accounting and tax report Web app system.  Generate clear dynamic statements and reports.
                      Accounting & Billing, Inventory + Accounting,  Budget, Invoice Automation
                      Best Accounting Software for Small Businesses`,
        aboutClient: `Felix Co is a business that provides services to clients in various industries,
                      who faced a problem with efficient and organized management of finance and accounting`,
        generalChallanges: `The system is distributed as an online SaaS to many clients, so it has to be adaptable and 
                            scalable to meet the requirements of businesses from various industries.
                            Also the response time and stability are crucial for this web application.
                            The need to create clear and dynamic financial reports, which will be structured on various topics.`,
        solutions: `The Solid One team turned the Customer's needs into requirements and implemented some sections that address them.
                    Tax forms, Accounting - sections in which you can create custom reports and corporate documentation. 
                    To ensure the appropriate level of security and control of documents at the regulatory level, the roles of 
                    supervisor, manager, and co-author have been created. For effective interaction between employees,  has been 
                    created a News section, where you can follow the main events and changes in projects. The convenience of working 
                    in the environment can be adjusted by setting the interface according to the needs of users. An important feature 
                    is the import and export of reports.`,
        results: `The custom Accounting and Tax Report Web App system has transformed the way Felix Co Accounting manages 
                  their clients' financials. 
                  The web app features an intuitive and user-friendly interface, making it easy for the company 
                  to manage their clients' accounts. 
                  The app system also automates various accounting processes, including accounting and billing,
                  inventory management, budgeting, and invoice automation.`
    },
    {
        img: 'img/cases/Portal.svg',
        mark: 'NFT',
        title: '7th Portal First NFT Drop Collection Landing Page',
        description: `Lending page, Web and Mobile version for 7Portal was created with tech Best Load Balancers & 
                      load Balancing Web app. Using Gatsby and AWS.  When it comes to maintaining the performance 
                      and availability of your website, load balancers and load balancing software play a critical role. 
                      These tools help distribute incoming traffic across multiple servers, reducing the risk of overload 
                      and downtime. Our selection of the best load balancers and load balancing software for website, 
                      particularly on AWS are designed to meet the needs of businesses of all sizes, from small start-ups 
                      to enterprise-level organizations. With features such as automatic failover, real-time monitoring, 
                      and traffic routing, these load balancers and load balancing software are equipped to handle even 
                      the most demanding traffic loads. Whether you need to balance the load on your website, 
                      improve performance, or increase availability, AWS has the perfect solution for that.`,
        aboutClient: `The client came to us with the idea to create the first-ever webisode of the historic comics franchise  – 
                      “The 7th Portal - Let The Game Begin”.
                      It was the first set of superheroes created by Stan Lee since he created the X-Men over three decades earlier. 
                      Together we made a new way to own and trade digital comics that is safe and convenient.`,
        generalChallanges: `The website must be visually appealing and provide a user-friendly experience that engages users. 
                            This requires careful consideration of the site's design, navigation, 
                            and overall aesthetic to ensure that it appeals to fans of the franchise.
                            Mobile compatibility: With an increasing number of users accessing the web from mobile devices, 
                            the site must be optimized for viewing on smaller screens and different screen resolutions.
                            This requires careful consideration of the site's design and layout.`,
        solutions: `We created the site's navigation to be simple and intuitive, allowing users to find what they are 
                    looking for easily.
                    The work was done by experienced artists to create a visually appealing and engaging design.
                    Our team used interactive elements such as animations, hover effects, and other visual cues.
                    For mobile devices, we removed any unnecessary elements that could cause clutter on smaller screens.
                    Implemented responsive design that automatically adjusts the site's layout based on the device's screen size.`,
        results: `The final product was well received by the client, and they were impressed 
                  with the level of creativity and solutions that our team was able to bring to the table. 
                  After evaluating visual appeal, ease of use and overall functionality
                  Solid One received another commission to develop a similar project.`
    },
    {
        img: 'img/cases/Slam.svg',
        mark: 'NFT',
        title: 'SLAM Girl NFT Collection Website',
        description: `Website for World first NFT collection sale especially for the purpose Best Load Balancers & 
                      Load Balancing Web app. Using Gatsby and AWS.  When it comes to maintaining the performance 
                      and availability of your website, load balancers and load balancing software play a critical role. 
                      These tools help distribute incoming traffic across multiple servers, reducing the risk of 
                      overload and downtime. Our selection of the best load balancers and load balancing software for 
                      website, particularly on AWS are designed to meet the needs of businesses of all sizes, from 
                      small start-ups to enterprise-level organizations. With features such as automatic failover, 
                      real-time monitoring, and traffic routing, these load balancers and load balancing software are 
                      equipped to handle even the most demanding traffic loads. Whether you need to balance the 
                      load on your website, improve performance, or increase availability, AWS has the perfect solution for that.`,
        aboutClient: `The customers decided to revive the creation of Stan Lee, famous for Marvel Studios. Slam Girl is a comic 
                      book character who has gained a new life in the NFT series. This is the second joint project in the field 
                      of NFT that the SolidOne team has implemented for the customer.`,
        generalChallanges: `The main tasks have remained the same since the first cooperation between the client and our team.
                            To create a high-quality and attractive design. To keep the site simple and maintain the overall 
                            original style of the project. To create sections that will describe the path of the project, unite 
                            fans of the franchise, and show the history of the character. Provide high bandwidth for users`,
        solutions: `Solid One implemented load balancing to distribute traffic evenly across multiple servers. 
                    Tested website's performance using load testing tools and completed necessary optimizations.
                    Realize, caching to reduce the number of requests to the server and decrease response times.
                    Designers grouped content by types, such, as Backstory, Roadmap, Art, Community, Origin and Wepisode, 
                    to make it easy for users to find what they're looking for. Connected website to social media accounts 
                    to enable users to easily share and engage with content.The website is optimized for different devices and 
                    screen sizes, including mobile devices, to provide a smooth and consistent user experience.A unique style of 
                    landing was created taking into account the stylistics of the genre and the times of character creation`,
        results: `The result of cooperation was the complete transformation of the superhero from the pages of the comic into the 
                  digital space.
                  The site has become a simple and affordable way for fans to once again immerse themselves in the atmosphere 
                  of fantastic events. 
                  Every fan of this character was able to become the owner of a digital version in the form of NFT.`
    },
    {
        img: 'img/cases/Berenice.svg',
        mark: 'E-commerce',
        title: 'Development of a Ecommerce Berenice Online Shop',
        description: `Introducing our online shop for women, where you can find a wide selection of stylish 
                      and trendy clothing, shoes, and accessories. From everyday essentials to special 
                      occasion wear, our collection is curated to cater to the modern woman's fashion needs. 
                      We offer a convenient and easy-to-use shopping experience, with features such as detailed 
                      product descriptions, multiple images, size charts and customer reviews to help you make 
                      informed purchasing decisions. With our frequent sales, new arrivals and exclusive deals, 
                      you are sure to find something you love at a great price. Shop now and elevate your wardrobe 
                      with our fashionable selection.`,
        aboutClient: `Berenice is a company offering men's and women's clothing.
                      Its mission is to provide customers with the highest quality products and satisfy their unique fashion sense. 
                      Whether it's a casual outfit or a sophisticated look for a 
                      special event, there is something for everyone.`,
        generalChallanges: `Berenice needed a product that would combine an online store 
                            and the company's process management and control system. 
                            The solution should allow customers to simply place orders. 
                            Optimize and integrate different payment methods, 
                            avoiding long chains to the target customer action. 
                            Create a simple and user-friendly design for product and order pages.`,
        solutions: `The development team optimized the platform, revised the structure and overall functionality.
                    This made it easy to update the store's assortment.
                    An important point was the development and customization of cross-platform versions of the site, 
                    including adaptation for mobile devices. 
                    cross-platform versions of the site, including adaptation for mobile devices, with the ability 
                    to pay online using various bank payment methods.
                    Designers created a special minimalist style for the online store. 
                    The creation of a reliable CRM system allows you to responsibly handle customer orders and control 
                    the quantity of goods in the warehouse.`,
        results: `The ability to process and receive orders from all over the world opens up
                  the prospect of expanding the customer base. Previewing the product
                  quickly ensures the selection of the right size and other product characteristics.
                  The solutions implemented in the product have increased brand awareness 
                  and solved the problem of manual processing of orders through social networks.`
    },
];

export default casesArray;