import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import '../Modal/Modal.scss';

const ModalCases = ({ modalTitle, isOpen, toggleModal }) => {
  return (
    <div className={classNames('Modal', { 'is-active': isOpen })}>
      <button
        className="Modal__btn"
        onClick={toggleModal}
      >
        <span />
        <span />
      </button>
      <div className="Modal__wrapper">
        <h2>{modalTitle}</h2>
      </div>
    </div>
  );
};

ModalCases.propTypes = {
  modalTitle: PropTypes.string,
}

export default ModalCases;