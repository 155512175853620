import React, { useState } from "react";
import Button from "../Button";
import filterBattons from "./filterButtons";
import casesArray from "./casesArray";
import ModalCases from "./ModalCases";
import { navigate } from "gatsby";
import Modal from "../Modal";

const Cases = ({toggleModal, data}) => {
    const [casesForRender, setCasesForRender] = useState(casesArray);
    const [isOpen, setIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [orderType, setOrderType] = useState('');
    const [orderText, setOrderText] = useState('');

    const handleClick = (e) => {
        const elButtons = document.getElementsByClassName('filter-cases');
        for(let i = 0; i < elButtons.length; i++) {
            elButtons[i].classList.remove('active');
        }
        e.target.classList.add('active');
        if(e.target.name === 'All') {
            setCasesForRender(casesArray);
        } else {
            setCasesForRender(casesArray.filter((caseItem) => caseItem.mark === e.target.name));          
        }
    }

    const handleClickCase = (e) => {
        // const currentIndex = Number(e.currentTarget.attributes["index"].value);
        // navigate("/case", { state: {caseItem: casesArray[currentIndex], data} });
    }

    const toggleModalLearnMore = (e) => {
        e.stopPropagation();
        const caseInsex = e.currentTarget.name;
        if (!!caseInsex) {
          const caseTitle = casesArray[caseInsex].title;
          setModalTitle(caseTitle);
          setOrderText(`I want to learn more about ${caseTitle}`);
        } else {
          setOrderText("I want to learn more about SolidOne, cases & services.");
        }
        setOrderType("learn-more");
        setIsOpen(!isOpen);
    }

    const toggleModalDescription = (e) => {
      e.stopPropagation();
      const caseInsex = e.currentTarget.name;
      if (!!caseInsex) setModalTitle(casesArray[caseInsex].description);
      setIsOpen(!isOpen);
    };

    return(
    <div className="container" id="cases">
        <h2 className="cases-title">
            Our cases
            <span className="Section__info-line"></span>
        </h2>
        <div className="buttons__container">
            {filterBattons.map((button, i) => (
                <Button
                    name={button.title}
                    title={button.title}
                    onClick={handleClick}
                    key={i}
                    buttonClassName={`filter-cases ${button.classActive ? button.classActive : ''}`}
                />
            ))}
        </div>
        <div className="section-cases">
            {casesForRender.map((caseItem, i) => {
                return(
                    <div className="section-cases__case" onClick={handleClickCase} index={i} key={i}>
                            <img src={caseItem.img} alt="" />
                        <div className="section-cases__case--title_wrapper">
                            <div className="section-cases__case--mark">
                                {caseItem.mark}
                            </div>
                            <h2>{caseItem.title}</h2>
                            <div className="section-cases__case--button-wraper">
                                <a 
                                  className="button-more" 
                                  href="https://cal.com/team/solidone-software-ou/solidone-meet-everybody"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                    <img src="img/arrow-right-top.svg"></img>
                                    <span>Consult Today</span>
                                </a>
                                <button className="button-more second" onClick={toggleModalLearnMore} name={i}>
                                    <img src="img/arrow-right-top.svg"></img>
                                    <span>Learn more</span>
                                </button>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
        <ModalCases
            isOpen={isOpen}
            toggleModal={toggleModalDescription}
            modalTitle={modalTitle}
        />
        <Modal 
            isOpen={isOpen}
            toggleModal={toggleModalLearnMore}
            modalTitle={modalTitle}
            initialOrderValue={orderType}
            initialText={orderText}
        />
    </div>
    )
};

export default Cases;
