// import React from 'react';
// import { withPrefix } from 'gatsby';

// const data = [
//   {
//     title: 'React',
//     icon: `${withPrefix('/')}img/technologies/react.svg`,
//   },
//   {
//     title: 'Spring',
//     icon: `${withPrefix('/')}img/technologies/react.svg`,
//   },
//   {
//     title: 'Kotlin',
//     icon: `${withPrefix('/')}img/technologies/react.svg`,
//   },
//   {
//     title: '&&&&',
//     icon: `${withPrefix('/')}img/technologies/react.svg`,
//   },
// ];

// const Technologies = () => (
//   <div className="section-grid columns-4">
//     {data.map((e, i) => (
//       <div className="section-grid-item" key={i}>
//         <div className="section-grid-icon">
//           <img src={e.icon} alt={e.title} />
//         </div>
//         <div className="section-grid-desc">
//           <h4>{e.title}</h4>
//         </div>
//       </div>
//     ))}
//   </div>
// );

// export default Technologies;

import React from 'react';
import PropTypes from 'prop-types';

const Technologies = ({ data }) => (
  <div className="section-grid columns-4">
    {data.map((e, i) => (
      <figure className="section-grid-item" key={i}>
        <div className="section-grid-icon">
          {/* <PreviewCompatibleImage imageInfo={e} /> */}
          {/* <img src={e.image.childImageSharp.fluid.src} alt={e.title} /> */}
          <img src={e.image.publicURL} alt={e.title} />
        </div>
        <figcaption className="section-grid-desc">
          {e.title}
        </figcaption>
      </figure>
    ))}
  </div>
);

Technologies.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
    })
  ),
}

export default Technologies;
