import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from '../../components/FormSend';

import './Modal.scss';

const Modal = ({ modalTitle, isOpen, toggleModal, initialOrderValue, initialText }) => {
  return (
    <div className={classNames('Modal', { 'is-active': isOpen })}>
      <button
        className="Modal__btn"
        onClick={toggleModal}
      >
        <span />
        <span />
      </button>
      <div className="Modal__wrapper">
        <h2>{modalTitle}</h2>
        <Form
          modalField
          btnText="Send request"
          initialOrderValue={initialOrderValue}
          initialText={initialText}
        />
      </div>
    </div>
  );
};

Modal.propTypes = {
  modalTitle: PropTypes.string,
}

export default Modal;
