import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

// const data = [
//   {
//     title: 'Standard fixed price plan',
//     subtitle: 'Simplest solutions for small business',
//     list: [
//       {text: 'Plain landing pages'},
//       {text: 'Standard e-commerce solutions'},
//       {text: 'Simple webapps'},
//     ],
//   },
//   {
//     title: 'Hourly based agile plan',
//     subtitle: 'Scalable solutions on-demand',
//     list: [
//       {text: 'Projects of different scales'},
//       {text: 'Custom solutions for business'},
//       {text: 'Grow-as-you-go: on-demand team extending'},
//       {text: 'Dynamic availability: use only resources you need'},
//     ],
//   },
//   {
//     title: 'Full time dedicated team plan',
//     subtitle: 'Enterprise solutions on long-term basis',
//     list: [
//       {text: 'Top-level experts'},
//       {text: 'High availability'},
//       {text: 'Extra performance'},
//       {text: 'Synergy & concentration'},
//       {text: 'Instant support'},
//     ],
//   },
// ];

const Plan = ({ data, toggleModal }) => (
  <div className="container">
    <div className="section-grid columns-3">
      {data.map((e, i) => (
        <div className="section-grid-item" key={i}>
          <div className="section-grid-desc">
            <h4>{e.title}</h4>
            <p>{e.subtitle}</p>
            <ul>
              {e.items.map((item) => (
                <li key={item}>{item}</li>
              ))}
            </ul>
          </div>
          <Button
            onClick={() => toggleModal(e.orderType)}
            title="Order now"
            sizeSM
          />
        </div>
      ))}
    </div>
  </div>
);

Plan.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
      orderType: PropTypes.string,
      items: PropTypes.array,
    })
  ),
}

export default Plan;
