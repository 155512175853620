import React from 'react';
import PropTypes from 'prop-types';

const Experience = ({ data }) => (
  <ul>
    {data.map((e, i) => (
      <li key={i}>- {e.title}</li>
    ))}
  </ul>
);

Experience.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
}

export default Experience;